import React from 'react';
import { TrackingProvider } from '@volvo-cars/tracking';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import JSSPlaceholderRenderer from '../../JSSPlaceholderRenderer';
import { PageLayoutProps } from '../../PageLayoutProps';

const ChildSeatsPage: React.FC<PageLayoutProps> = ({
  header,
  placeholders,
}) => {
  const { marketName, locale, regionCode } = useCurrentMarketSite();
  return (
    <TrackingProvider
      market={marketName}
      locale={locale}
      trackPageLoad
      enableReactTracking={false}
      marketLanguage={locale}
      countryCode={regionCode}
    >
      {header}
      <main>
        <JSSPlaceholderRenderer renderings={placeholders['model-intro']} />
        <JSSPlaceholderRenderer renderings={placeholders['cta-link']} />
        <JSSPlaceholderRenderer renderings={placeholders['hero']} />
        <JSSPlaceholderRenderer renderings={placeholders['image-with-text']} />
        <JSSPlaceholderRenderer
          renderings={placeholders['promotions-text-image']}
        />
        <JSSPlaceholderRenderer
          renderings={placeholders['zig-zag-promotion']}
        />
        <JSSPlaceholderRenderer renderings={placeholders['disclaimer']} />
      </main>
    </TrackingProvider>
  );
};

export default ChildSeatsPage;
